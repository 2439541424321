import { Component, Host, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EmailValidateComponent } from '@protected/email-validate/email-validate.component';


@Component({
  selector: 'app-email-validate-confirm',
  templateUrl: './email-validate-confirm.component.html',
  styleUrls: ['./email-validate-confirm.component.scss']
})
export class EmailValidateConfirmComponent implements OnInit {

  constructor(
    private _router: Router,
    @Host() _emailValidateComponent: EmailValidateComponent,
    private _translate: TranslateService
  ) {
    this._translate.use(localStorage.getItem('language'));
    this.emailValidateComponent = _emailValidateComponent;
  }

  @Input("email")
  email: string = '';
  private emailValidateComponent: EmailValidateComponent;

  ngOnInit() {

  }

  resendEmail() {
    this.emailValidateComponent.prepareAndSendConfirmEmail(this.email);
  }

  redirectToLogin() {
    this._router.navigate(["login"]);
  }

}
