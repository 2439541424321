<div class="container-fluid ">
    <div class="col-sm-12">

        <div class="col-sm-12 m-0 text-center">
            <p class="title-topic">{{'CONFIRM_EMAIL.TITLE' | translate}}</p>
        </div>

        <div class="p-2">

        </div>

        <div class="col-sm-12">
            <div class="m-4 custom-border text-center">
                <div class="col-sm-12 pt-3">
                    <p class="pt-2 pb-2">{{email}}</p>
                </div>

                <div class="col-sm-12">
                    <p>
                        {{'CONFIRM_EMAIL.DISCRIPTION' | translate}}
                    </p>
                </div>
            </div>
        </div>

        <div class="p-4">

        </div>

        <div class="p-0 m-0 d-flex justify-content-center pt-3">
            <div class="p-2 d-flex d-inline-block">
                <div class="">
                    <button (click)="resendEmail()"
                        class="btn btn-default btn-email-2 pl-3 pr-3">{{'CONFIRM_EMAIL.B_R_EMAIL' | translate}}</button>
                </div>

                <div class="p-3"></div>

                <div class="">
                    <button (click)="redirectToLogin()" class="btn btn-primary btn-email pl-3 pr-3">{{'CONFIRM_EMAIL.B_LOGIN' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>