import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  exportAs: "datepicker"
})
export class DatepickerComponent {

  diId: number = Math.floor(Math.random() * 99999);
  isDisable: boolean = false;

  @Input('disable')
  set disableValue(disableTmp: boolean) {
    if (disableTmp != null) {
      this.isDisable = disableTmp;
    }
  }

  @Output('onChange')
  onChangeEE: EventEmitter<Date> = new EventEmitter();

  constructor() { }

  // initial date must be yyyy-mm-dd
  onShowWithOutput(yyyymmdd: string, emptyOnStart: boolean = false) {
    let datePicker = $(`#dateInput-${this.diId}`).pickadate({
      format: 'd mmmm, yyyy',
      onSet: (context) => {
        // When pickadate get value, it is using locale timezone. Convert it to UTC.
        let newDate = new Date(context.select);
        let offset = newDate.getTimezoneOffset();
        newDate.setMinutes(newDate.getMinutes() - offset)

        // Update date value
        this.onChangeEE.emit(context.select == null ? null : newDate);
      }
    });

    if (emptyOnStart) {
      datePicker.pickadate('picker').set('clear');
    }
    else {
      if (yyyymmdd != null) {
        datePicker.pickadate('picker').set('select', yyyymmdd, { format: 'yyyy-mm-dd' });
      }
      else {
        datePicker.pickadate('picker').set('clear');
      }
    }
  }

  // initial date must be yyyy-mm-dd
  onShow(control: AbstractControl, yyyymmdd: string, emptyOnStart: boolean = false) {
    let datePicker = $(`#dateInput-${this.diId}`).pickadate({
      format: 'd mmmm, yyyy',
      onSet: (context) => {
        // When pickadate get value, it is using locale timezone. Convert it to UTC.
        let newDate = new Date(context.select);
        let offset = newDate.getTimezoneOffset();
        newDate.setMinutes(newDate.getMinutes() - offset)

        // Update date value
        control.markAsDirty();
        control.patchValue(context.select == null ? null : newDate);
      }
    });

    if (emptyOnStart) {
      datePicker.pickadate('picker').set('clear');
    }
    else {
      if (yyyymmdd != null) {
        datePicker.pickadate('picker').set('select', yyyymmdd, { format: 'yyyy-mm-dd' });
      }
      else {
        if (control.value != null) {
          datePicker.pickadate('picker').set('select', control.value);
        }
      }
    }
  }

  // initial date must be yyyy-mm-dd
  onShowWithMin(control: AbstractControl, yyyymmdd: string, min: Date) {
    let datePicker = $(`#dateInput-${this.diId}`).pickadate({
      min: min,
      format: 'd mmmm, yyyy',
      onSet: (context) => {
        // When pickadate get value, it is using locale timezone. Convert it to UTC.
        let newDate = new Date(context.select);
        let offset = newDate.getTimezoneOffset();
        newDate.setMinutes(newDate.getMinutes() - offset)

        // Update date value
        if (control != null) {
          control.markAsDirty();
          control.patchValue(context.select == null ? null : newDate);
        }
      }
    });

    if (yyyymmdd != null) {
      datePicker.pickadate('picker').set('select', yyyymmdd, { format: 'yyyy-mm-dd' });
    }
    else {
      if (control.value != null) {
        datePicker.pickadate('picker').set('select', control.value);
      }
    }

    // Once date is setup. The actual date in the control may differ from what being displayed due to minimum date requirement.
    // e.g. Today is 5 and the current value of control's date is 3. When date picker appear, it will show 5 as user may not select
    //  date in the past. In such case, we must reflect what being shown on UI back to the model.
    if (control != null) {
      control.markAsDirty();
      let selectedDate = datePicker.pickadate('picker').get("value");
      if (selectedDate != null) {
        // Since the date from pickadate is a string representation without timezone info. When parsed, it is in UTC.
        // We need to convert it to current timezone here.
        let gmtSelectedDate = new Date(selectedDate);
        let dateInCurrentTZ = new Date(Date.UTC(gmtSelectedDate.getFullYear(), gmtSelectedDate.getMonth(), gmtSelectedDate.getDate()));
        control.patchValue(dateInCurrentTZ);
      }
    }
  }

  // initial date must be yyyy-mm-dd
  onShowWithMax(control: AbstractControl, yyyymmdd: string, max: Date) {
    let datePicker = $(`#dateInput-${this.diId}`).pickadate({
      max: max,
      format: 'd mmmm, yyyy',
      onSet: (context) => {
        // When pickadate get value, it is using locale timezone. Convert it to UTC.
        let newDate = new Date(context.select);
        let offset = newDate.getTimezoneOffset();
        newDate.setMinutes(newDate.getMinutes() - offset)

        // Update date value
        control.markAsDirty();
        control.patchValue(context.select == null ? null : newDate);
      }
    });

    if (yyyymmdd != null) {
      datePicker.pickadate('picker').set('select', yyyymmdd, { format: 'yyyy-mm-dd' });
    }
    else {
      if (control.value != null) {
        datePicker.pickadate('picker').set('select', control.value);
      }
    }
  }
}
