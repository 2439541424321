import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {RegisterICTemporaryAccountModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/registerICTemporaryAccountModel';
import {LicenseCurrentPeriodResult} from '@proflink/prof-link-web-ic-api-ts-angular';
import * as WSCall from '@app/utils/WSCall';
import {IcProfileControllerService} from '@proflink/prof-link-web-ic-api-ts-angular/api/icProfileController.service';
import {ReSendEmailRequest} from '@proflink/prof-link-web-ic-api-ts-angular/model/reSendEmailRequest';
import {APIResultstring} from '@proflink/prof-link-web-ic-api-ts-angular/model/aPIResultstring';
import {IcProfileModel} from '@proflink/prof-link-web-ic-api-ts-angular/model/icProfileModel';
import {AppState} from '@core/app-state';
import { Router } from '@angular/router';
import * as Utils from '@app/utils/Utils';
import {DatePipe} from "@angular/common";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-email-validate-form',
  templateUrl: './email-validate-form.component.html',
  styleUrls: ['./email-validate-form.component.scss']
})
export class EmailValidateFormComponent implements OnInit {

  constructor(
    private _router: Router,
    private _datePipe: DatePipe,
    private _translate: TranslateService
  ) { 
    this._translate.use(localStorage.getItem('language'));
  }
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
  
  @Output('completeState')
  completeState: EventEmitter<ReSendEmailRequest> = new EventEmitter();

  @Input('lastSentEmail')
  lastSentEmail = '-';

  lastSentEmailDate: Date = null;
  lastSentEmailDateStr = '-';

  @Input('lastSentEmailDate')
  set setLastSentDate(lastSentEmailDate: Date){
    if(lastSentEmailDate != null){
      this.lastSentEmailDate = lastSentEmailDate;
      this.lastSentEmailDateStr = this._datePipe.transform(lastSentEmailDate, 'dd-MM-yyyy HH:mm');
    }
  }




  @Input('email')
  email = '';

  @Input('confirmEmail')
  confirmEmail = '';

  ngOnInit(): void {

  }

  onConfirmEmail(){

    //const regex = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passValidate: boolean = emailRegex.test(this.email);
    if (!passValidate){
      Utils.error('กรุณากรอกอีเมล '+ this.email+' ให้ถูกต้อง');
      return;
    }

    if(this.email.toLocaleLowerCase() != this.confirmEmail.toLocaleLowerCase()){
      Utils.error('ยืนยันอีเมลระหว่าง ' + this.email +' และ '+this.confirmEmail+ ' ไม่ต้องถูกต้อง');
      return;
    }

    if (this.email === ''){
      Utils.error('กรุณากรอกอีเมล');
      return;
    }

    const request: ReSendEmailRequest = {
        email : this.email
    };

    this.completeState.emit(request);
  }

  redirectToLogin(){
    this._router.navigate(['login']);
  }



}
