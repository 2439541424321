<div class="container-fluid ">
    <div class="col-sm-12">

        <div class="col-sm-12 m-0 text-center">
            <p class="title-topic">{{'VERIFY_EMAIL.TITLE' | translate}}
            </p>
        </div>

        <div class="p-2">

        </div>

        <div class="col-sm-12">
            <div class="m-4 custom-border text-center">
                <p class="pt-5 pb-5">{{'VERIFY_EMAIL.ACCEPT_VERIFY_EMAIL_DISCRIPTION' | translate}}
                </p>
            </div>
        </div>

        <div class="p-4">

        </div>

        <div class="col-sm-12 text-center pb-3 pt-3">
          <button (click)="confirmInformation()" class="btn btn-primary btn-email pl-5 pr-5">{{'BUTTON.ACKNOWLEDGE' | translate}}</button>
        </div>
    </div>
</div>
