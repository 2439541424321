<div class="container-fluid ">
    <div class="col-sm-12">
        <div class="col-sm-12 m-0 text-center">
            <p class="title-topic">{{'VERIFY_EMAIL.TITLE' | translate}}
            </p>
        </div>

        <div class="col-sm-12">
            <div class="m-2 custom-border text-center">
                <div class="col-sm-12 pt-3">
                    <p>{{'VERIFY_EMAIL.EMAIL_REGISTER' | translate}}</p>
                </div>

                <div class="col-sm-12 m-0 p-0">
                    <p style="color: blue">{{lastSentEmail}}<br />
                        {{lastSentEmailDateStr}}
                    </p>
                </div>

                <div class="col-sm-12 text-center">
                    <p>
                        {{'VERIFY_EMAIL.DISCRIPTION1' | translate}}
                    </p>
                </div>

                <div class="col-sm-12 text-left">
                    <label>{{'VERIFY_EMAIL.T_EMAIL' | translate}}</label>
                </div>
                <div class="col-sm-12">
                    <input class="form-control text-center register-normal" [(ngModel)]="email" />
                </div>

                <div class="col-sm-12 text-left">
                    <label>{{'VERIFY_EMAIL.T_CONFIRM_EMAIL' | translate}}</label>
                </div>

                <div class="col-sm-12">
                    <input class="form-control text-center register-normal" [(ngModel)]="confirmEmail" />
                </div>

                <div class="p-4">

                </div>

            </div>
        </div>

        <div class="p-0 m-0 d-flex justify-content-center pt-3">
            <div class="p-2">
                <button (click)="onConfirmEmail()" class="btn btn-primary btn-email pl-5 pr-5">{{'BUTTON.SUBMIT' | translate}}</button>
            </div>
        </div>
    </div>
</div>