import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropzoneComponent } from './components/dropzone/dropzone.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';


@NgModule({
  declarations: [DropzoneComponent, DatepickerComponent],
  exports: [DropzoneComponent, DatepickerComponent],
  imports: [
    CommonModule
  ]
})
export class FeatureModule { }
