<div class="box-container">
    <div class="box">
        <div class="card card-body">
            <h4>{{'COURSE_ACTIVITY.T_HEADER' | translate }}</h4>
            <p style="padding-left: 2rem; margin: 0;">{{'COURSE_ACTIVITY.T_DESCRIPTION' | translate }}</p>
        </div>
        <div class="card card-body" style="margin-top: 2rem;">
            <label>{{'COURSE_ACTIVITY.T_HISTORY' | translate }}</label>
            <div class="card card-body">
                <form [formGroup]="filterForm">
                    <div class="filter-section">
                        <div class="row">
                            <div class="col-md-4 col-sm-6 col-12 mb-3">
                                <label for="requestedFrom">Requested Date from</label>
                                <!-- <input type="date" id="requestedFrom" formControlName="requestedFrom"
                                    class="form-control" (change)="onRequestedFilterChanged($event,'from')" /> -->
                                <app-datepicker #requestedFrom="datepicker"
                                    (onChange)="onDateFilterChanged($event,'requestedFrom')"></app-datepicker>
                            </div>
                            <div class="col-md-4 col-sm-6 col-12 mb-3">
                                <label for="requestedTo">to</label>
                                <!-- <input type="date" id="requestedTo" formControlName="requestedTo" class="form-control"
                                    (change)="onRequestedFilterChanged($event,'to')" /> -->
                                <app-datepicker #requestedTo="datepicker"
                                    (onChange)="onDateFilterChanged($event,'requestedTo')"></app-datepicker>
                            </div>
                            <div class="col-md-4 col-12 mb-3">
                                <label for="areaOfKnowledge">Area of Knowledge</label>
                                <ng-multiselect-dropdown [placeholder]="'Select Area of Knowledge'"
                                    formControlName="areaOfKnowledge" [data]="listOfCategory"
                                    [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                                    (onDeSelect)="onItemDeSelect($event)">
                                </ng-multiselect-dropdown>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-md-4 col-sm-6 col-12 mb-3">
                                <label for="approvedFrom">Approved Date from</label>
                                <app-datepicker #approvedFrom="datepicker"
                                    (onChange)="onDateFilterChanged($event,'approvedFrom')"></app-datepicker>
                                <!-- <input type="date" id="approvedFrom" formControlName="approvedFrom" class="form-control"
                                    (change)="onApprovedFilterChanged($event,'from')" /> -->
                            </div>
                            <div class="col-md-4 col-sm-6 col-12 mb-3">
                                <label for="approvedTo">to</label>
                                <app-datepicker #approvedTo="datepicker"
                                    (onChange)="onDateFilterChanged($event,'approvedTo')"></app-datepicker>
                                <!-- <input type="date" id="approvedTo" formControlName="approvedTo" class="form-control"
                                    (change)="onApprovedFilterChanged($event,'to')" /> -->
                            </div>
                            <div class="col-md-4 col-12 mb-3">
                                <label>Status</label>
                                <div class="status-checkboxes d-flex flex-wrap">
                                    <div class="form-check me-3 mb-2">
                                        <input type="checkbox" id="statusRequested" [value]="'Requested'"
                                            (change)="onSelectStatus($event)" class="form-check-input">
                                        <label for="statusRequested" class="form-check-label">Requested</label>
                                    </div>
                                    <div class="form-check me-3 mb-2">
                                        <input type="checkbox" id="statusApproved" [value]="'Approved'"
                                            (change)="onSelectStatus($event)" class="form-check-input">
                                        <label for="statusApproved" class="form-check-label">Approved</label>
                                    </div>
                                    <div class="form-check me-3 mb-2">
                                        <input type="checkbox" id="statusRejected" [value]="'Rejected'"
                                            (change)="onSelectStatus($event)" class="form-check-input">
                                        <label for="statusRejected" class="form-check-label">Rejected</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 col-12 mb-3">
                                <label for="approvalCode">Approval Code / Course Name</label>
                                <input type="text" id="approvalCode" formControlName="approvalCode" class="form-control"
                                    (input)="onChangedApprovalCode($event)" />
                            </div>
                            <div class="col-md-6 col-12 mb-3" style="align-content: end; text-align: center;">
                                <button type="button" class="btn btn-secondary button-pending" (click)="onReset()"><img
                                        src="/assets/icons/reset.svg" />Reset</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="form-group pt-3 pb-0 text-start">
                <button type="button" class="btn btn-primary btn-next button-pending" style="color: white;"
                    (click)="onCreateCourse()">+ Create New</button>
            </div>

            <div class="table-container">
                <table id="example" class="table table-striped table-bordered zero-configuration" style="width:100%">
                    <thead>
                        <tr>
                            <th>Approval Code</th>
                            <th>Course Name</th>
                            <th>Modified Date</th>
                            <th>Approved Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>