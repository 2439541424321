import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, AfterViewChecked, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { wsCall } from '@app/utils/WSCall';
import * as Dropzone from 'dropzone';
import * as Utils from '@app/utils/Utils';
import { WindowRef } from '@app/utils/Window';
import { FileStoreModel, ProtectedMiscControllerService } from '@proflink/prof-link-web-ic-api-ts-angular';

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss']
})
export class DropzoneComponent implements AfterViewChecked {
  _files: FileStoreModel[] = new Array();

  @Input("files")
  set files(s: FileStoreModel[]) {
    this._files = s;

    if (this.dzObj == null) {
      setTimeout(() => {
        this.initializeDropzone()
        this.updateFileList();
      }, 500);
    }
    else if (this.dzObj != null) {
      this.updateFileList();
    }
  }

  @Input("acceptedFiles")
  acceptedFiles: string = null;

  get files(): FileStoreModel[] {
    return this._files;
  }

  _disable: boolean = false;
  @Input("disable")
  set disable(disable: boolean) {
    this._disable = disable;
    
    this.updateDisableStatus();
  }

  @Output()
  filesChange: EventEmitter<FileStoreModel[]> = new EventEmitter();

  @Output('fsModelProvider')
  fileStoreProviderEE: EventEmitter<FileStoreProvider> = new EventEmitter();
  
  @Output('fileStoreModel')
  fileStoreEventEmitter: EventEmitter<FileStoreModel> = new EventEmitter();

  dzId: number = Math.floor(Math.random() * 99999);
  dzObj: Dropzone;
  uploadingFsModel: FileStoreModel;
  uploadingUrl: string;

  previewImgUrl: string = null;

  inProcessFile: any = null;
  pendingQueue: any[] = [];

  get allFiles(): FileStoreModel[] {
    return this.dzObj.files.map((f) => (<any>f).dzFileStore);
  }

  constructor(protected _httpClient: HttpClient,
    private _protMiscService: ProtectedMiscControllerService,
    private winRef: WindowRef) {
      (<any>Dropzone).autoDiscover = false;
    }

  ngAfterViewChecked(): void {
    this.initializeDropzone();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.files && changes.files.currentValue&& changes.files.currentValue.length == 0) {
      if (this.dzObj) {
        this.dzObj.removeAllFiles();
      }
    }
  }

  initializeDropzone() {
    if (this.dzObj != null) {
      return;
    }

    let _dzThis = this;
    this.dzObj = new Dropzone(`#dropzone-${this.dzId}`, {
      url: "/",
      method: "put",
      parallelUploads: 1,
      uploadMultiple: false,
      autoProcessQueue: true,
      clickable: true,
      addRemoveLinks: true,
      acceptedFiles: _dzThis.acceptedFiles,
      removedfile(file) {
        // Trigger file list update
        if (_dzThis.filesChange != null) {
          _dzThis.filesChange.emit(_dzThis.allFiles);
          _dzThis.fileStoreProviderEE.emit({
            onComplete(fs: FileStoreModel) {
              // Do nothing
            }
          });
        }

        if (file.previewElement != null) {
          file.previewElement.parentElement.removeChild(file.previewElement);
        }

        _dzThis.dzObj.files = _dzThis.dzObj.files.filter((f) => f != file);
        
        // if (_dzThis.pendingQueue != null) {
        //   _dzThis.pendingQueue = _dzThis.pendingQueue.filter((qEntry) => _dzThis.dzObj.files.includes(qEntry[0]));
        // }

        // If file is removed while it is being uploaded, mark inProcessFile which represents currently uploading file as null. (As it is just removed!)
        if (_dzThis.inProcessFile == file) {
          _dzThis.inProcessFile = null;
        }
      },
      sending(file, xhr) {
        let tmpFile = file;
        // Hijack request here
        // let _open = xhr.open;
        let _send = xhr.send
        xhr.send = () => {
          let _dzOnLoad = xhr.onload;
          xhr.onload = async (ev: Event) => {
            // Upload finished -- Completing upload with our server
            (<any>tmpFile)._uploadingFsModel = await _dzThis.completeUpload(
              (<any>tmpFile)._uploadingFsModel.fileId,
              file.name
            );
            _dzThis.fileStoreEventEmitter.emit((<any>tmpFile)._uploadingFsModel);

            // Add our custom ID to this file.
            (<any>file).dzFileStore = (<any>tmpFile)._uploadingFsModel;

            // Call original onLoad impl
            _dzOnLoad.call(xhr, ev);

            // Trigger file list update
            if (_dzThis.filesChange != null) {
              _dzThis.filesChange.emit(_dzThis.allFiles);
            }

            // Get new item from queue to process
            while (_dzThis.pendingQueue.length > 0) {
              let params = _dzThis.pendingQueue.pop();
              if (_dzThis.dzObj.files.includes(params[0])) {
                _dzThis.processAccept(params[0], params[1]);
                break;
              }
            }

            // This clear the way for subsequent request to allow them to do upload once queue is empty.
            if (_dzThis.pendingQueue.length == 0) {
              _dzThis.inProcessFile = null;
            }
          };

          // Override send URL to our S3 location
          xhr.open('PUT', _dzThis.uploadingUrl);
          _send.call(xhr, file);
        }
      },
      async accept(file, done) {
        if (file.size > 15 * 1024 * 1024) {
          // Check file size
          // This is to handle the case when the file cannot be uploaded.
          // This allows user to click to remove the preview icon.
          if (file.previewElement != null) {
            file.previewElement.parentElement.removeChild(file.previewElement);
          }

          Utils.warning("File is too large and cannot be uploaded.");

          return;
        }
        
        if (_dzThis.inProcessFile == null) {
          _dzThis.inProcessFile = file;
        }
        else {
          _dzThis.pendingQueue.push([file, done]);
          return;
        }

        _dzThis.processAccept(file, done);
      }
    });

    // Handle download back of uploaded files in dropzone
    this.dzObj.on("complete", (file) => {
      file.previewElement.addEventListener("click", () => {
        let fs: FileStoreModel = (<FileStoreModel>(<any>file)._uploadingFsModel);
        if (fs != null) {
          if (fs.fileType.startsWith("image")) {
            this.previewImgUrl = fs.downloadLink;
            _dzThis.winRef.nativeWindow.open(fs.downloadLink, '_blank');
          }
          else {
            // Download file
            _dzThis.winRef.nativeWindow.location.replace(fs.attachmentLink);
          }
        }
        else {
          // This is to handle the case when the file cannot be uploaded.
          // This allows user to click to remove the preview icon.
          if (file.previewElement != null) {
            file.previewElement.parentElement.removeChild(file.previewElement);
          }
        }
      });
    });

    this.updateDisableStatus();
  }

  updateDisableStatus() {
    if (this.dzObj != null) {
      if (this._disable) {
        this.dzObj.disable();
      }
      else {
        this.dzObj.enable();
      }
    }
  }

  processAccept(file: any, done: any): void {
    let _dzThis = this;
    let tmpFile = file;
    this.fileStoreProviderEE.emit({
      onComplete(fs: FileStoreModel) {
        tmpFile._uploadingFsModel = fs;
        _dzThis.uploadingUrl = fs.uploadLink;
        done()
      }
    })
  }

  updateFileList(): void {
    console.log("Updating file list");
    if (this.files != null && this.files.length != this.allFiles.length) {
      // Add initial files to dropzone
      // Create the mock file:
      this.files.forEach(file => {
        var mockFile = {
          name: file.fileName,
          size: file.fileSize,
          dzFileStore: file,
          processing: false,
          accepted: true,
          type: file.fileType,
          _uploadingFsModel: file,
          dataURL: file.downloadLink
        };
  
        // Call the default addedfile event handler
        this.dzObj.emit("addedfile", mockFile);
    
        // And optionally show the thumbnail of the file:
        let type: string = file.fileType;

        if (type.startsWith("image")) {
          // To resize image using the same logic as Dropzone
          this.dzObj.createThumbnailFromUrl.call(this.dzObj, <any>mockFile, 120, 120, 'crop', true, (thumbnail: any) =>{
            this.dzObj.emit("thumbnail", mockFile, thumbnail);
          }, "anonymous");
        }

        // Complete it (this will remove the progress bar)
        this.dzObj.emit("complete", mockFile);

        // Add our file into dropzone handle file list
        this.dzObj.files.push(<any>mockFile);
      });
    }
  }

  async completeUpload(fileId: number, fileName: string): Promise<FileStoreModel> {
    return wsCall(() => {
      return this._protMiscService.completeUploadUsingPOST(fileId, {
        fileName: fileName,
        fileTitle: ""
      })
    }, (respBody) => {
      return respBody.data;
    });
  }
}

export interface FileStoreProvider {
  onComplete(fs: FileStoreModel): void;
}