import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { APIResultFileStoreModel, APIResultstring, CategoryModel, CompleteUploadRequest, CourseActivityControllerService, CourseActivityModel, CourseCategoryModel, FileStoreModel, HistoryResponse, IcProfileModel, IndividualCategoryRequest, ProtectedMiscControllerService, PublicMiscControllerService, TSICASCategoryModel, TSICASIndividualModel, TSICASIndividualUpdateRequestModel } from '@proflink/prof-link-web-ic-api-ts-angular';
import * as WSCall from '@app/utils/WSCall';
import { AppState } from '@app/core/app-state';
import { ActivatedRoute, Router } from '@angular/router';
import * as Utils from '@app/utils/Utils';
import { FileUploadService } from '@app/service/file-service';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { FileStoreProvider } from '@app/feature/components/dropzone/dropzone.component';
import { DatepickerComponent } from '@app/feature/components/datepicker/datepicker.component';

@Component({
  selector: 'app-create-course-activity',
  templateUrl: './create-course-activity.component.html',
  styleUrls: ['./create-course-activity.component.scss']
})
export class CreateCourseActivityComponent implements OnInit {
  @Input("courseActivityId")
  set courseActivityId(courseActivityId: number) {
    if (courseActivityId) {
      this.activityId = courseActivityId;
    }
  }

  @ViewChildren('lectureDateFrom')
  lectureDateFrom: QueryList<DatepickerComponent>;

  @ViewChildren('lectureDateTo')
  lectureDateTo: QueryList<DatepickerComponent>;


  title = 'Create New Activity';
  activityForm: FormArray;
  hours: number[] = [];
  minutes: number[] = [];
  courseActivityList: Array<CourseActivityModel> = [];
  icProfile: IcProfileModel;
  // proofParticipationFiles: Array<FileStoreModel> = [];
  //MOCK
  activityId: number;
  //
  listOfCategory: CategoryModel[] = [];
  isCreate: boolean = true;
  courseDetail: CourseActivityModel;
  historyActionCourse: Array<HistoryResponse> = [];
  showTable: boolean = true;

  individualCode: string;
  statusMessage: string = '';
  rejectTime: string = '';
  approveDate: string = '';
  reason: string = '';
  requestEditStatus: string;
  statusFlow: string;
  constructor(
    private _appState: AppState,
    private fb: FormBuilder,
    private _courseActivityControllerService: CourseActivityControllerService,
    private _router: Router,
    private route: ActivatedRoute,
    private _fileUploadService: FileUploadService,
    protected _httpClient: HttpClient,
    private _protMiscService: ProtectedMiscControllerService,
    private _datePipe: DatePipe,
  ) {
    this.activityForm = this.fb.array([]);
    this.initializeOptions();
  }


  async ngOnInit() {
    await this.route.paramMap.subscribe(params => {
      this.activityId = +params.get('individualId');
    });

    console.log(this.activityId);
    this.icProfile = this._appState.currentUser;
    this.getListOfCategory().then((result: CourseCategoryModel[]) => {
      this.listOfCategory = result;
    });
    this.addActivity();
    if (this.activityId != 0) {
      this.setActivityForm();
    } else {
      this.setupStartEndDatePickerCreate();
    }
  }

  initializeOptions() {
    this.hours = Array.from({ length: 13 }, (_, i) => i);
    this.minutes = Array.from({ length: 60 }, (_, i) => i);
  }

  setupStartEndDatePickerCreate() {
    setTimeout(() => {
      this.lectureDateFrom.forEach(datepicker => {
        datepicker.onShowWithOutput(null, false);
      });
      this.lectureDateTo.forEach(datepicker => {
        datepicker.onShowWithOutput(null, false);
      });
    });
  }

  setupStartEndDatePicker() {
    setTimeout(() => {
      this.lectureDateFrom.forEach(datepicker => {
        datepicker.onShowWithOutput(this.activityForm.controls[0].get('lectureDateFrom').value, false);
      });
      this.lectureDateTo.forEach(datepicker => {
        datepicker.onShowWithOutput(this.activityForm.controls[0].get('lectureDateTo').value, false);
      });
    });
  }

  request() {
    let icId = localStorage.getItem('ic_id');
    this._courseActivityControllerService.requestEditUsingPUT(this.activityId, Number(icId)).subscribe((res) => {
      if (res.resultCode == "SUCCESS") {
        Utils.success('Request for Edit sucessfully');
        this.setActivityForm();
      } else {
        let error = res.errorMessage;
        Utils.error(error ? error : 'Unable to request for edit');
      }
    }, (error) => {
      Utils.error('Unable to request for edit');
    })
  }

  async setActivityForm() {
    this.courseDetail = await this.getDetailCCourseActivity();

    this.historyActionCourse = this.courseDetail.historyList;
    this.requestEditStatus = this.courseDetail.requestEditStatus;
    this.statusMessage = this.courseDetail.statusFlowMessage;
    this.statusFlow = this.courseDetail.statusFlow;
    this.individualCode = this.courseDetail.individualCode;
    this.approveDate = this.courseDetail.approveDateTime;

    if (this.statusFlow === 'REJECTED') {
      this.reason = this.historyActionCourse[0].reason;
      this.rejectTime = this._datePipe.transform(this.historyActionCourse[0].createdDate, 'dd/MM/yyyy HH:mm:ss');
    }

    this.activityForm.at(0).patchValue({
      activityType: this.courseDetail.type,
      activityTypeMessage: this.courseDetail.type === 'I' ? 'Speaker' : this.courseDetail.type === 'P' ? 'Lecturer' : this.courseDetail.type === 'A' ? 'Class Attendee' : this.courseDetail.type === 'E' ? 'e-Learning Attendee' : '',
      courseName: this.courseDetail.topic,
      institute: this.courseDetail.location,
      lectureDateFrom: new Date(this.courseDetail.trainingDateFrom).toISOString().split("T")[0],
      lectureDateTo: new Date(this.courseDetail.trainingDateTo).toISOString().split("T")[0],
      lectureDateFromMessage: this._datePipe.transform(new Date(this.courseDetail.trainingDateFrom), 'dd/MM/yyyy'),
      lectureDateToMessage: this._datePipe.transform(new Date(this.courseDetail.trainingDateTo), 'dd/MM/yyyy'),
      hoursLecture: this.courseDetail.lecturerHours,
      minutesLecture: this.courseDetail.lecturerMins,
      topicDetail: this.courseDetail.topicDetail,
      areaOfKnowledge: this.courseDetail.individualCategory.length > 0 ? this.listOfCategory.find(x => parseInt(x.categoryCode) === this.courseDetail.individualCategory[0].categoryCode).categoryCode : null,
      areaOfKnowledgeMessage: this.courseDetail.individualCategory.length > 0 ? this.listOfCategory.find(x => parseInt(x.categoryCode) === this.courseDetail.individualCategory[0].categoryCode).categoryName : '',
      hoursCredit: this.courseDetail.individualCategory.length > 0 ? this.courseDetail.individualCategory[0].hours : null,
      minutesCredit: this.courseDetail.individualCategory.length > 0 ? this.courseDetail.individualCategory[0].mins : null,
      courseOutlineFiles: this.courseDetail.courseOutlineFiles,
      proofOfParticipation: this.courseDetail.proofFiles,
      coordinatorName: this.courseDetail.coordinatorName,
      coordinatorMobileNumber: this.courseDetail.coordinatorMobileNumber,
    });

    if (this.courseDetail.requestEditStatus != 'ALLOWED') {
      this.title = 'View Activity';
      this.activityForm.disable();
    } else if (this.courseDetail.requestEditStatus === 'ALLOWED') {
      this.title = 'Edit Activity';
    }
    this.isCreate = false;
    this.setupStartEndDatePicker();

  }

  addActivity() {
    let coordinatorName = '';
    let coordinatorMobileNumber = '';

    if (this.activityForm.length > 0) {
      coordinatorName = this.activityForm.at(0).get('coordinatorName').value;
      coordinatorMobileNumber = this.activityForm.at(0).get('coordinatorMobileNumber').value;
    }

    const activityGroup = this.fb.group({
      activityType: ['', Validators.required],
      activityTypeMessage: [null],
      courseName: ['', Validators.required],
      institute: ['', Validators.required],
      lectureDateFrom: ['', Validators.required],
      lectureDateTo: ['', Validators.required],
      lectureDateFromMessage: [null],
      lectureDateToMessage: [null],
      hoursLecture: [0, Validators.required],
      minutesLecture: [0, Validators.required],
      topicDetail: ['', Validators.required],
      areaOfKnowledge: [null, Validators.required],
      areaOfKnowledgeMessage: [null],
      hoursCredit: [0, Validators.required],
      minutesCredit: [0, Validators.required],
      courseOutlineFiles: [[], Validators.required],
      proofOfParticipation: [[], Validators.required],
      coordinatorName: [coordinatorName, Validators.required],
      coordinatorMobileNumber: [coordinatorMobileNumber, Validators.required],
    }, { validator: this.dateRangeValidator() });

    this.activityForm.push(activityGroup);
    this.setupStartEndDatePickerCreate();
  }

  dateRangeValidator(): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: any } | null => {
      const lectureDateFrom = formGroup.get('lectureDateFrom')?.value;
      const lectureDateTo = formGroup.get('lectureDateTo')?.value;

      if (lectureDateFrom && lectureDateTo && new Date(lectureDateFrom) > new Date(lectureDateTo)) {
        return { dateRangeInvalid: true };
      }
      return null;
    };
  }

  onDateFilterChanged(date: Date, condition: 'lectureDateFrom' | 'lectureDateTo', index: number) {
    // Helper function to format date and time
    if (date == null) {
      return;
    }
    const formatDateTime = (date: Date, condition: 'lectureDateFrom' | 'lectureDateTo'): string => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const time = condition === 'lectureDateFrom' ? '00:00' : '23:59'
      return `${year}-${month}-${day}T${time}:00`;
    };

    // Format the date and time
    const formattedDateTime = formatDateTime(date, condition);

    switch (condition) {
      case 'lectureDateFrom':
        this.activityForm.controls[index].get('lectureDateFrom').setValue(formattedDateTime);
        // console.log('lectureDateFrom', formattedDateTime);
        break;
      case 'lectureDateTo':
        this.activityForm.controls[index].get('lectureDateTo').setValue(formattedDateTime);
        // console.log('lectureDateTo', formattedDateTime);
        break;
    }
  }

  removeActivity(index: number) {
    if (this.activityForm.length > 1) {
      this.activityForm.removeAt(index);
    }
  }

  resetActivities() {
    if (this.activityId != 0) {
      this.setActivityForm();
    } else {
      this.activityForm.clear();
      this.addActivity();
      this.setupStartEndDatePickerCreate();
    }

  }

  async saveActivities() {
    console.log(this.activityForm);
    for (let index = 0; index < this.activityForm.length; index++) {
      if (this.activityForm.at(index).get('proofOfParticipation').value.length > 0) {
        this.activityForm.at(index).get('proofOfParticipation').setErrors(null);
      } else {
        this.activityForm.at(index).get('proofOfParticipation').setErrors([Validators.required]);
      }

      if (this.activityForm.at(index).get('courseOutlineFiles').value.length > 0) {
        this.activityForm.at(index).get('courseOutlineFiles').setErrors(null);
      } else {
        this.activityForm.at(index).get('courseOutlineFiles').setErrors([Validators.required]);
      }
    }

    if (this.activityForm.valid) {
      this.activityForm.value.forEach((activity: { areaOfKnowledge: any; hoursCredit: any; minutesCredit: any; activityType: any; courseName: any; institute: any; lectureDateFrom: any; lectureDateTo: any; hoursLecture: any; minutesLecture: any; topicDetail: any; courseOutlineFiles: any; proofOfParticipation: any; coordinatorName: any; coordinatorMobileNumber: any; }) => {
        let areaOfKnowledgeList: Array<IndividualCategoryRequest> = [];
        let areaOfKnowledge: IndividualCategoryRequest = {
          categoryCode: activity.areaOfKnowledge,
          categoryName: '',
          hours: activity.hoursCredit,
          mins: activity.minutesCredit
        };
        areaOfKnowledgeList.push(areaOfKnowledge);
        let icId = localStorage.getItem('ic_id');
        let courseActivity: CourseActivityModel = {
          individualCode: "",
          individualId: this.activityId == 0 ? null : this.activityId,
          icId: Number(icId),
          type: activity.activityType,
          topic: activity.courseName,
          location: activity.institute,
          trainingDateFrom: activity.lectureDateFrom,
          trainingDateTo: activity.lectureDateTo,
          lecturerHours: activity.hoursLecture,
          lecturerMins: activity.minutesLecture,
          topicDetail: activity.topicDetail,
          individualCategory: areaOfKnowledgeList,
          courseOutlineFiles: activity.courseOutlineFiles,
          proofFiles: activity.proofOfParticipation,
          coordinatorName: activity.coordinatorName,
          coordinatorMobileNumber: activity.coordinatorMobileNumber,
        };
        this.courseActivityList.push(courseActivity);
      });
      let result: APIResultstring = await this.saveCourseActivity();
      if (result.resultCode === 'SUCCESS') {
        Utils.success("บันทึกข้อมูลสำเร็จ");
        this._router.navigate(['/course-activity']);

      } else {
        Utils.error(result.errorMessage);
      }
    } else {
      Utils.error("กรุณากรอกข้อมูลให้ครบถ้วน");
    }

  }

  toggleTable() {
    this.showTable = !this.showTable;
  }

  async requestPosterUploadUrl(fsProvider: FileStoreProvider) {
    fsProvider.onComplete(await this.getDownloadLinkFile());
  }

  async requestCourseOutlineUploadUrl(fsProvider: FileStoreProvider) {
    fsProvider.onComplete(await this.getLinkUploadCourseOutlineFile());
  }

  async onChanges(event: any, index: number) {
    let fileArray: FileStoreModel[] = [];
    if (this.activityForm.at(index).get('proofOfParticipation').value !== null) {
      fileArray = this.activityForm.at(index).get('proofOfParticipation').value;
      fileArray = event;
      this.activityForm.at(index).get('proofOfParticipation').setValue(fileArray);
    }
    // console.log(fileArray);
  }

  async onChangesCourseOutline(event: any, index: number) {
    let fileArray: FileStoreModel[] = [];
    if (this.activityForm.at(index).get('courseOutlineFiles').value !== null) {
      fileArray = this.activityForm.at(index).get('courseOutlineFiles').value;
      fileArray = event;
      this.activityForm.at(index).get('courseOutlineFiles').setValue(fileArray);
    }
    // console.log(fileArray);
  }



  // async onFileSelected(event: any, index: number) {
  //   console.log(event);
  //   let fileArray: FileStoreModel[] = [];
  //   if (this.activityForm.at(index).get('proofOfParticipation').value !== null) {
  //     fileArray = this.activityForm.at(index).get('proofOfParticipation').value;
  //   }
  //   for (let i = 0; i < event.target.files.length; i++) {
  //     let file: File = event.target.files[i];
  //     let uploadingFs = await this.getDownloadLinkFile();
  //     let completeUpload: CompleteUploadRequest = {};

  //     await this._fileUploadService.uploadWithFileIgnoreComplete(file, this._httpClient, this._protMiscService, uploadingFs.fileId, uploadingFs.uploadLink, async () => {

  //       await this._protMiscService.completeUploadUsingPOST(uploadingFs.fileId, { fileTitle: file.name, fileName: file.name }).subscribe((resp) => {
  //         // this.proofParticipationFiles.push(resp.data);
  //         fileArray.push(resp.data);
  //       });

  //     }, () => {
  //       // On fail
  //       // this.blockUI.stop();
  //     });
  //   }
  //   this.activityForm.at(index).get('proofOfParticipation').setValue(fileArray);
  //   // Reset file input
  //   event.target.value = '';
  // }

  // removeFile(index: number, fileIndex: number) {
  //   let fileArray: FileStoreModel[] = this.activityForm.at(index).get('proofOfParticipation').value;
  //   fileArray.splice(fileIndex, 1);
  //   this.activityForm.at(index).get('proofOfParticipation').setValue(fileArray);
  // }

  async deleteActivity() {
    Utils.confirmDeleteDialog("coures", async () => {
      let result: APIResultstring = await this.deleteCourseActivity();
      if (result.resultCode === 'SUCCESS') {
        this._router.navigate(['/course-activity']);
      } else {
        Utils.error(result.errorMessage);
      }
    });

  }

  async getListOfCategory(): Promise<CategoryModel[]> {
    return WSCall.wsCall(
      () => {
        return this._courseActivityControllerService.getListCategoryUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getDetailCCourseActivity(): Promise<CourseActivityModel> {
    return WSCall.wsCall(
      () => {
        return this._courseActivityControllerService.getCourseActivityUsingGET(this.activityId);
      },
      respBody => {
        return respBody.data;
      }
    );

  }

  async saveCourseActivity(): Promise<APIResultstring> {
    return WSCall.wsCall(
      () => {
        return this._courseActivityControllerService.updateCoursesActivityByIcUsingPOST(this.courseActivityList);
      },
      respBody => {
        return respBody;
      }
    );
  }

  async deleteCourseActivity(): Promise<APIResultstring> {
    return WSCall.wsCall(
      () => {
        return this._courseActivityControllerService.deleteCourseActivityUsingGET(this.activityId);
      },
      respBody => {
        return respBody;
      }
    );
  }

  async getDownloadLinkFile(): Promise<FileStoreModel> {
    return WSCall.wsCall(
      () => {
        return this._courseActivityControllerService.getUploadLinkForDocumentActivityUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getLinkUploadCourseOutlineFile(): Promise<FileStoreModel> {
    return WSCall.wsCall(
      () => {
        return this._courseActivityControllerService.getUploadLinkForCourseOutlineUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }
}
