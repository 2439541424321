import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReSendEmailRequest } from "@proflink/prof-link-web-ic-api-ts-angular/model/reSendEmailRequest";

@Component({
  selector: 'app-email-validate-info',
  templateUrl: './email-validate-info.component.html',
  styleUrls: ['./email-validate-info.component.scss']
})
export class EmailValidateInfoComponent implements OnInit {

  constructor(
    private _translate: TranslateService
  ) {
    this._translate.use(localStorage.getItem('language'));
  }

  @Output('completeState')
  completeState: EventEmitter<boolean> = new EventEmitter();

  ngOnInit(): void {
  }

  confirmInformation() {

    this.completeState.emit(true);

  }

}
