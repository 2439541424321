<div [formGroup]="registerForm" class="custom-input mt-3">
    <div class="form-group">
        <div class="col-sm-12">
            <ng-container>
                <div class="form-group m-0 text-left">
                    <label class="register-info">Title<span class="text-danger">*</span></label>
                </div>
                <div class="form-group">
                    <select name="title" id="title" class="form-control" formControlName="title">
                        <option value="1">Mr.</option>
                        <option value="2">Mrs.</option>
                        <option value="3">Ms.</option>
                    </select>
                </div>
            </ng-container>

            <div class="form-group m-0 text-left">
                <label class="register-info">First Name<span class="text-danger">*</span></label>
            </div>
            <div class="form-group">
                <input class="form-control required" formControlName="firstName" required />
            </div>

            <div class="form-group m-0 text-left">
                <label class="register-info">Middle Name</label>
            </div>
            <div class="form-group">
                <input class="form-control required" formControlName="middleName" />
            </div>

            <div class="form-group m-0 text-left">
                <label class="register-info">Last Name<span class="text-danger">*</span></label>
            </div>
            <div class="form-group">
                <input class="form-control required" formControlName="lastName" required />
            </div>

            <ng-container>
                <div class="form-group m-0 text-left">
                    <label class="register-info">Gender<span class="text-danger">*</span></label>
                </div>
                <div class="form-group">
                    <select name="gender" id="gender" class="form-control" formControlName="gender">
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                    </select>
                </div>
            </ng-container>

            <div class="form-group m-0 text-left">
                <label class="register-info">Date of Birth<span class="text-danger">*</span></label>
            </div>
            <div class="form-group">
                <input type="date" class="form-control required" formControlName="dateOfBirth" required>
            </div>

            <div class="form-group m-0 text-left">
                <label class="register-info">Mobile Number<span class="text-danger">*</span></label>
            </div>
            <div class="form-group">
                <input class="form-control required" formControlName="mobileNumber" required />
            </div>

            <!-- <div class="form-group m-0 text-left">
                <label class="register-info">e-Mail<span class="text-danger">*</span></label>
            </div>
            <div class="form-group">
                <input class="form-control required" formControlName="email" required />
            </div> -->

            <ng-container>
                <div class="form-group m-0 text-left">
                    <label class="register-info">Nationality<span class="text-danger">*</span></label>
                </div>
                <div class="form-group">
                    <select name="nationality" id="nationality" class="form-control" formControlName="nationality">
                        <option *ngFor="let i of nationalityList" [ngValue]="i.nationalityId">
                            {{ i.nationalityEn }}
                        </option>
                    </select>
                </div>
            </ng-container>

            <div class="form-group m-0 text-left">
                <label class="register-info">Passport No.<span class="text-danger">*</span></label>
            </div>
            <div class="form-group">
                <input class="form-control required" formControlName="passportNo" required />
            </div>

            <div class="form-group m-0 text-left">
                <label class="register-info">Date of Expiry<span class="text-danger">*</span></label>
            </div>
            <div class="form-group">
                <input type="date" class="form-control required" formControlName="dateOfExpiry" [min]="today" required>
            </div>

            <div class="form-group m-0 text-left">
                <label class="register-info">Passport photo<span class="text-danger">*</span> <a
                        class="mx-1 text-warning pl-2 pr-2 cursor-pointer" (click)="openInfo(infoPassportPhoto)">
                        <i class="fas fa-info-circle fa-sm"></i></a></label>
            </div>
            <div class="form-group">
                <label class="btn-import">
                    <img src="/assets/icons/upload.svg" />
                    <input class="required" type="file" id="imgUploadPassportPhoto" style="display: none;"
                        accept="image/*" (change)="onFileSelected($event,'passportPhoto')"> Import
                </label>
                <span class="button-preview" *ngIf="registerForm.get('passportPhoto').value != null"
                    (click)="previewImage(requirePassportPhoto,'imgUploadPassportPhoto','imgPreviewPassportPhoto')">preview</span>
            </div>


            <div class="form-group m-0 text-left">
                <label class="register-info">Your Face photo<span class="text-danger">*</span> <a
                        class="mx-1 text-warning pl-2 pr-2 cursor-pointer" (click)="openInfo(infoFacePhoto)">
                        <i class="fas fa-info-circle fa-sm"></i></a></label>
            </div>
            <div class="form-group">
                <label class="btn-import">
                    <img src="/assets/icons/upload.svg" />
                    <input class="required" type="file" id="imgUploadFacePhoto" style="display: none;" accept="image/*"
                        (change)="onFileSelected($event,'facePhoto')"> Import
                </label>
                <span class="button-preview" *ngIf="registerForm.get('facePhoto').value != null"
                    (click)="previewImage(requireFacePhoto,'imgUploadFacePhoto','imgPreviewFacePhoto')">preview</span>
            </div>

            <div class="form-group m-0 text-left">
                <label class="register-info">Approval No.<span class="text-danger">*</span>
                    <span style="color: #FCB134;"> Do not remember? </span>
                    <span class="click-here-text" (click)="showModalSearchApproval()">click here</span></label>
            </div>
            <div class="form-group">
                <input class="form-control required" maxlength="6" formControlName="approvalNo" required />
            </div>


            <div class="form-group pt-3 pb-3">
                <button type="button" class="btn btn-secondary"
                    style="width: 100%; background-color: #D9D9D9; border-color: #D9D9D9;" (click)="resetForm()">
                    Reset
                </button>
            </div>

            <div class="form-group pt-0 pb-3">
                <button type="button" [disabled]="!canSubmit" class="btn btn-block btn-primary btn-next"
                    style="color: white;" (click)="submitNext()">
                    Next
                </button>
            </div>
        </div>
    </div>

    <ng-template #infoPassportPhoto let-modal>
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="text-center pt-3">
                <img src="assets/image/example_passport.jpeg" alt="BehindIDCard" class="mx-auto img-fluid"
                    style="max-width: 50%;">
                <div class="text-left pl-4">
                    <p class="title-custom pt-2 pl-3" style="text-align: center;">Please upload a picture of your
                        Passport that clearly shows the
                        information.</p>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #infoFacePhoto let-modal>
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="text-center pt-3">
                <img src="assets/image/example_face.png" alt="BehindIDCard" class="mx-auto img-fluid"
                    style="max-width: 50%;">
                <div class="text-left pl-4">
                    <p class="title-custom pt-2 pl-3" style="text-align: center;">Please upload a photo of yourself that
                        clearly shows your face.</p>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #requirePassportPhoto let-modal>
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card col-md-12 col-sm-12">
                <img id="imgPreviewPassportPhoto" src={{passportPhotoSrc}} alt="Image Preview">
            </div>
        </div>
    </ng-template>

    <ng-template #requireFacePhoto let-modal>
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card col-md-12 col-sm-12">
                <img id="imgPreviewFacePhoto" src={{facePhotoSrc}} alt="Image Preview">
            </div>
        </div>
    </ng-template>
</div>