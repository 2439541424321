import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SearchApprovalModelComponent } from '@app/shared/search-approval-model/search-approval-model.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AccountControllerService, CompleteUploadRequest, FileStoreModel, NationalityModel, ProtectedMiscControllerService, PublicMiscControllerService, SecCheckIcThaiQueryModel } from '@proflink/prof-link-web-ic-api-ts-angular';
import * as WSCall from '@app/utils/WSCall';
import { FileUploadService } from '@app/service/file-service';
import { HttpClient } from '@angular/common/http';
import { SecCheckIcForeignerQueryModel } from '@proflink/prof-link-web-ic-api-ts-angular/model/secCheckIcForeignerQueryModel';
import { AuthProvider } from '@app/core/auth/auth-provider.service';
import { DateConstant } from '@app/utils/DateUtils';

@Component({
  selector: 'app-register-foreigner-form',
  templateUrl: './register-foreigner-form.component.html',
  styleUrls: ['./register-foreigner-form.component.scss']
})
export class RegisterForeignerFormComponent implements OnInit {
  today: string;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private _publicMiscControllerService: PublicMiscControllerService,
    private _accountControllerService: AccountControllerService,
    private _fileUploadService: FileUploadService,
    protected _httpClient: HttpClient,
    private _protMiscService: ProtectedMiscControllerService,
    private _authProvider: AuthProvider
  ) {
    const currentDate = new Date();
    this.today = currentDate.toISOString().split('T')[0];
  }

  @Input('fromProfile')
  set setFromProfile(secCheckIcForeignerQueryModel: SecCheckIcForeignerQueryModel) {
    if (secCheckIcForeignerQueryModel != null) {
      this.fromRegister = secCheckIcForeignerQueryModel;
    }
  }

  @Input('consentVersionNo')
  consentVersionNo: string = '';

  @Output('requestRegisterEmit')
  requestRegisterEmit: EventEmitter<SecCheckIcThaiQueryModel> =
    new EventEmitter();

  public registerForm: FormGroup;
  canSubmit: boolean = false;
  nationalityList: Array<NationalityModel> = [];
  passportPhotoSrc: String;
  facePhotoSrc: String;
  fromRegister: SecCheckIcForeignerQueryModel;


  ngOnInit(): void {
    this.loadListNationality();
    this.createForm();
    if (this.fromRegister != null) {
      this.registerForm.controls['title'].setValue(this.fromRegister.title);
      this.registerForm.controls['firstName'].setValue(this.fromRegister.firstName);
      this.registerForm.controls['middleName'].setValue(this.fromRegister.middleName);
      this.registerForm.controls['lastName'].setValue(this.fromRegister.lastName);
      this.registerForm.controls['gender'].setValue(this.fromRegister.gender);
      this.registerForm.controls['dateOfBirth'].setValue(this.fromRegister.birthday);
      this.registerForm.controls['mobileNumber'].setValue(this.fromRegister.mobileNumber);
      // this.registerForm.controls['email'].setValue(this.fromRegister.email);
      this.registerForm.controls['nationality'].setValue(this.fromRegister.nationalityId);
      this.registerForm.controls['passportNo'].setValue(this.fromRegister.passportNo);
      this.registerForm.controls['dateOfExpiry'].setValue(this.fromRegister.dateOfExpiry);
      this.registerForm.controls['passportPhoto'].setValue(this.fromRegister.passportPhoto);
      this.registerForm.controls['facePhoto'].setValue(this.fromRegister.facePhoto);
      this.registerForm.controls['approvalNo'].setValue(this.fromRegister.approvalNo);

      this.passportPhotoSrc = this.fromRegister.passportPhoto.downloadLink;
      this.facePhotoSrc = this.fromRegister.facePhoto.downloadLink;
    }
  }

  createForm() {
    this.registerForm = this.fb.group({
      title: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      dateOfBirth: [null, Validators.required],
      mobileNumber: ['', Validators.required],
      // email: ['', Validators.required],
      nationality: [null, Validators.required],
      passportNo: ['', Validators.required],
      dateOfExpiry: [null, Validators.required],
      passportPhoto: [null, Validators.required],
      facePhoto: [null, Validators.required],
      approvalNo: ['', Validators.required],
    });

    this.registerForm.valueChanges.subscribe((changeObject) => {
      this.canSubmit = this.registerForm.valid;
    });
  }

  get f() {
    return this.registerForm.controls;
  }

  resetForm() {
    this.registerForm.reset();
  }

  async loadListNationality() {
    this.nationalityList = await this.getAllListNationality();
  }

  async getAllListNationality(): Promise<NationalityModel[]> {
    return WSCall.wsCall(
      () => {
        return this._publicMiscControllerService.getAllNationalitiesUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async getDownloadLink(): Promise<FileStoreModel> {
    let fileStoreUploadFile: FileStoreModel = await this.getUploadLinkForForeignerImage();

    const promise = new Promise<FileStoreModel>(function (resolve, reject) {
      resolve(fileStoreUploadFile);
    });

    return promise;
  }

  async getUploadLinkForForeignerImage(): Promise<FileStoreModel> {
    return WSCall.wsCall(
      () => {
        return this._accountControllerService.getUploadLinkForForeignerImageUsingGET();
      },
      respBody => {
        return respBody.data;
      }
    );
  }

  async onFileSelected(event: any, type: string) {
    try {
      let file: File = event.target.files[0];
      let uploadingFs = await this.getDownloadLink();
      let completeUpload: CompleteUploadRequest = {};

      await this._fileUploadService.uploadWithFileIgnoreComplete(file, this._httpClient, this._protMiscService, uploadingFs.fileId, uploadingFs.uploadLink, async () => {

        await this._publicMiscControllerService.completeUploadFileUsingPOST(uploadingFs.fileId, { fileTitle: file.name, fileName: file.name }).subscribe((resp) => {
          if (type == 'passportPhoto') {
            this.registerForm.controls['passportPhoto'].setValue(resp.data);
            this.passportPhotoSrc = resp.data.downloadLink;
          } else if (type == 'facePhoto') {
            this.registerForm.controls['facePhoto'].setValue(resp.data);
            this.facePhotoSrc = resp.data.downloadLink;
          }
        });

      }, () => {
        // On fail
        // this.blockUI.stop();
      });
      event.target.value = '';
    } catch (error) {
      console.error(error);
    }
  }

  showModalSearchApproval() {
    let modalRef = this.modalService.open(SearchApprovalModelComponent, {
      size: 'md',
    });
    modalRef.result.then((result) => {
      if (result != null || result != undefined || result != '') {
        this.registerForm.controls['approvalNo'].setValue(result);
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  async submitNext() {
    const deviceToken = await this._authProvider.validateAndCreateDeviceToken();
    const request: SecCheckIcForeignerQueryModel = {
      title: this.f.title.value.trim(),
      firstName: this.f.firstName.value.trim(),
      middleName: this.f.middleName.value,
      lastName: this.f.lastName.value.trim(),
      gender: this.f.gender.value.trim(),
      birthday: this.f.dateOfBirth.value.trim(),
      mobileNumber: this.f.mobileNumber.value.trim(),
      email: null,
      nationalityId: this.f.nationality.value,
      nationalityName: this.nationalityList.find(n => n.nationalityId == this.f.nationality.value).nationalityEn,
      passportNo: this.f.passportNo.value.trim(),
      dateOfExpiry: this.f.dateOfExpiry.value.trim(),
      passportPhoto: this.f.passportPhoto.value,
      facePhoto: this.f.facePhoto.value,
      icCode: this.f.approvalNo.value.trim(),
      approvalNo: this.f.approvalNo.value,
      consentVersionNo: this.consentVersionNo,
      uniqueToken: deviceToken,
    };
    this.requestRegisterEmit.emit(request);
  }

  previewImage(content: any, uploadName: string, previewName: string) {
    this.modalService.open(content);

    // var imgUpload = document.getElementById(uploadName) as HTMLInputElement;
    // var imgPreview = document.getElementById(previewName) as HTMLImageElement;
    // var file = imgUpload.files[0];
    // var reader = new FileReader();

    // reader.onloadend = function () {
    //   imgPreview.src = reader.result.toString();
    // }

    // if (file) {
    //   reader.readAsDataURL(file);
    // } else {
    //   imgPreview.src = "";
    // }
  }

  openInfo(content: any) {
    this.modalService.open(content, { size: 'md' });
  }

}
